import axios from './http'

const api = {
  base: {
    // 授权绑定
    addSysMsgCollocation: data => axios.post('/uaa/open/addSysMsgCollocation', data),
    // 登录
    goLogin: params => axios.post('/uaa/oauth/token', {}, { params }),
    // 查询角色权限
    listRoleByUserId: params => axios.get('/uaa/role/listRoleByUserId', { params }),
    // 获取我的额度
    quota: enterpriseId => axios.get(`/uaa//enterprise/admit/quota/quotaEffectivenessInfoByEnterpriseId/${enterpriseId}`),
    // 退出登录
    logOut: () => axios.get('/uaa/oauth/logout'),
    // 获取菜单
    getMenu: params => axios.get('/uaa/menu/listManageableMenus', { params }),
    // 获取企业管理员信息各项
    enterpriseAuthAdmin: id => axios.get(`/uaa/enterprise/app/auth_admin_item/${id}`),
    // 获取企业认证信息各项
    enterpriseAuth: id => axios.get(`/uaa/enterprise/app/auth_item/${id}`),
    // 企业注册
    authReg: data => axios.post('/uaa/enterprise/app/auth_reg', data),
    // 提交企业管理员信息各项
    submitAuthAdmin: data => axios.put('/uaa/enterprise/app/submit_auth_admin', data),
    // 提交企业认证信息各项
    submitAuthItem: data => axios.put('/uaa/enterprise/app/submit_auth_item', data),
    // 首次登录修改密码
    updateInfo: data => axios.put('/uaa/user/updateInfo', data),
    // 发送邮件
    sendEmail: data => axios.post('/uaa/open/sendEmail', data),
    // 根据照片识别身份 二要素判断
    checkIdCard: params => axios.get('/uaa/verification/checkIdCard', { params }),
    // 解析身份证信息
    parseIdCardByUrl: params => axios.get('/uaa/verification/parseIdCardByUrl', { params }),
    // 获取个人信息
    myInfo: () => axios.get('/uaa/user/self'),
    // 企业四要素校验
    enterpriseFourFactorVerify: params => axios.get('/uaa/verification/enterpriseFourFactorVerify', { params }),
    // 获取个人信息
    checkMobileLogin: params => axios.get('/uaa/open/checkMobileLogin', { params }),
    sendVerificationSms: params => axios.get('/uaa/open/sendVerificationSms', { params }),
    // 人脸核验
    faceValidate: params => axios.get('/uaa/verification/tengxun/detect-auth', { params }),
    // 人脸核验-成功
    faceDetectAuth: bizToken => axios.get(`/uaa/verification/tengxun/detect-auth/${bizToken}`),
    file: {
      upload: (formData, params) => axios.post('/uaa/file/upload', formData, { params, timeout: 60 * 10 * 1000 }),
      // 文件列表
      pageList: params => axios.get('/uaa/file/listPage', { params }),
      // 下载
      download: params => axios.get('/uaa/file/filedownload', { params, responseType: 'blob' }),
      // 删除文件
      delFile: params => axios.delete('/uaa/file/delByFileId', { params }),
      // 文件上传
      fileUpload: data => axios.post('/uaa/file/upload', data),
      // 文件详情
      getDetailByFileId: params => axios.get('/uaa/file/getDetailByFileId', { params })
    },
    // 获取数据字典
    dataDict: params => axios.get('/uaa/dict/findDataDictList', { params, customErr: { code: 401 } }),
    // 待办列表
    waitTodoList: params => axios.get('/workflow/work-flow/unprocessed', { params }),
    // 已办列表
    completeTodoList: params => axios.get('/workflow/work-flow/processed', { params }),
    // 推送消息跳转
    messagePush: params => axios.get('/workflow/workflowOpen/getTaskDetailById', { params }),
    // 获取省市区数据
    provinceCityInfo: params => axios.get('/uaa/dict/findDictProvinceCityInfoList', { params, customErr: { code: 401 } }),
    // 已办消息
    listDone: () => axios.get('/uaa/task/listDone'),
    // 待办消息
    listUpcoming: () => axios.get('/uaa/task/listUpcoming'),
    // 更新为已读
    listUpdate: id => axios.put(`/uaa/task/update/${id}`),
    // 获取消息
    myMessageList: params => axios.get('/uaa/message/listPage', { params }),
    // 获取消息详情
    messageDetail: id => axios.get(`/uaa/message/${id}`)
  },
  // 电签
  signature: {
    // 验证短信
    verification: params => axios.get('/uaa/eleSig/verification', { params })
  },
  system: {
    // 工作流
    workflow: {
      // 工作流字典
      dictList: () => axios.get('/workflow/work-dict')
    }
  },
  audit: {
    // 初始化工作流
    workflowInit: params => axios.get('/workflow/work-flow/initWorkFlow', { params }),
    // 用户列表
    userList: params => axios.get('/uaa/user/listUserByRoleId', { params }),
    // 创建工作流
    workflowCreate: data => axios.post('/workflow/work-flow/create', data),
    // 通过
    submit: data => axios.post('/workflow/work-flow/approval', data),
    // 返回上一节点
    back: data => axios.post('/workflow/work-flow/back', data),
    // 返回首节点
    revoke: data => axios.post('/workflow/work-flow/revoke', data),
    // 终止
    termination: data => axios.post('/workflow/work-flow/termination', data),
    // 退回客户
    return_customer: data => axios.post('/workflow/work-flow/returnCustomer', data),
    // 任务详情
    taskDetail: id => axios.get(`/workflow/work-flow/detail/${id}`),
    // 历史任务详情
    taskDetailHistory: id => axios.get(`/workflow/work-flow/history/${id}`)
  },
  project: {
    listPage: params => axios.get('/uaa/project/listPage', { params }),
    // 列表农投
    listPageNongtou: params => axios.get('/uaa/project/nt/listPage', { params }),
    // 查询详情
    detail: params => axios.get('/uaa/project/getDetailByProjectId', { params }),
    // 查询详情农投
    detailNongtou: params => axios.get('/uaa/project/nt/getDetailByProjectId', { params }),
    // 产品模式模板
    productModeTemple: () => axios.get('/uaa/project/listCacheProjectProductModeTemp'),
    // 获取项目下产品模式数据
    productModeDetail: params => axios.get('/uaa/project/listProductModeInfo', { params }),
    // 获取垫资信息
    fundsInfo: params => axios.get('/uaa/project/getProjectAdvancePaymentInfo', { params }),
    // 项目列表  下拉选择
    list: params => axios.get('/uaa/project/list', { params })
  },
  cargoManagement: {
    // 分类列表 下拉
    categoryList: params => axios.get('/uaa/cargo-management/listCargoClassify', { params }),
    cargoDetail: params => axios.get('/uaa/cargo-management/selectCargoDetailById/' + params),
    // 获取货物列表
    goodsList: params => axios.get('/uaa/cargo-management/listCargo', { params })
  },
  // 提货
  delivery: {
    // 查询信息详情
    packOutDetail: stockOutId => axios.get(`/uaa/storage/pack/out/detail/${stockOutId}`),
    // 提货记录（按包装）列表带分页
    packExtractableListPage: params => axios.get('/uaa/storage/pack/out/extractableListPage', { params }),
    // 按客户可提货列表 --带分页
    packListPage: params => axios.get('/uaa/stock/pack/container/extractList', { params }),
    // 出库柜详情
    packOutCabinetInfoDetail: stockContainerId => axios.get(`/uaa/stock/pack/container/cabinetInfo/${stockContainerId}`),
    // 新增提货（按包装）
    packOutAdd: data => axios.post('/uaa/storage/pack/out/add', data)
  },
  // 合同管理
  contract: {
    // 分页列表
    listPage: params => axios.get('/uaa/seal/listPage', { params }),
    // 用印申请详情
    detail: id => axios.get(`/uaa/seal/getInfoById/${id}`),
    // 我的合同
    myContract: params => axios.get('/uaa/seal/myContract', { params }),
    // 发送邮件
    sendEmail: params => axios.get('/uaa/seal/sendEmail', { params }),
    invoice: {
      // 分页列表
      pageList: params => axios.get('/uaa/invoice/list', { params }),
      // 发票详情
      detail: uuid => axios.get(`/uaa/invoice/getInvoice/${uuid}`),
      // 申请开票保存
      addApply: data => axios.post('/uaa/invoice/add', data),
      // 删除发票
      del: uuid => axios.delete(`/uaa/invoice/${uuid}`),
      // 修改发票
      updateInvoice: data => axios.put('/uaa/invoice/update', data),
      // 其他性质企业列表
      otherCompanyList: orgType => axios.get(`/uaa/enterprise/list/select/available/${orgType}`),
      // 企业列表
      companyList: orgType => axios.get(`/uaa/enterprise/admit/list/selector/${orgType}`),
      // 企业客户列表
      listEnterpriseInvoice: params => axios.get('/uaa/invoice/listEnterpriseInvoice', { params }),
      // 获取订单列表
      orderList: () => axios.get('/uaa/order/list')
    }
  },
  // 订单
  order: {
    // 查看仓库详情
    warehouseInfo: id => axios.get(`/uaa/storage/warehouse/${id}`),
    // 订单详情
    detail: id => axios.get(`/uaa/order/getDetailById/${id}`),
    // 订单详情
    detailNongtou: params => axios.get('/uaa/order/nt/getDetailByOrderId', { params }),
    // 订单管理List列表
    listPage: params => axios.get('/uaa/order/listPage', { params: params }),
    // 订单-供应商详情
    getEnterpriseDetail: id => axios.get(`/uaa/enterprise/get/${id}`),
    // 订单-供应商账号
    getAccountList: id => axios.get(`/uaa/enterprise/account/list/${id}`),
    // 查看供应商信息
    getSupplierNongtou: params => axios.get('/uaa/order/nt/getSupplier', { params }),
    // 添加订单
    addOrder: data => axios.post('/uaa/order/add', data),
    // 添加订单 农投
    addOrderNt: data => axios.post('/uaa/order/nt/add', data),
    // 编辑订单
    editOrder: data => axios.put('/uaa/order/updateOrderInfo', data),
    // 编辑订单
    editOrderNt: data => axios.put('/uaa/order/nt/updateOrderInfo', data),
    // 提交订单
    submitOrder: data => axios.post('/uaa/order/submit', data),
    // 查看农投交易方案
    dealPlanNongtou: params => axios.get('/uaa/order/nt/getTradingScheme', { params }),
    // 新增农投交易方案
    AddDealPlanNongTou: data => axios.post('/uaa/order/nt/addTradingScheme', data),
    // 更新农投交易方案
    updateDealPlanNongTou: data => axios.put('/uaa/order/nt/updateTradingScheme', data),
    // 获取项目下产品模式数据
    productModeDetail: params => axios.get('/uaa/order/listProductModeInfo', { params }),
    // 获取垫资信息
    fundsInfo: params => axios.get('/uaa/order/getOrderAdvancePaymentInfo', { params }),
    // 获取订单客户信息
    getCustomerDetailByOrderId: params => axios.get('/uaa/order/nt/getCustomerDetailByOrderId', { params })
  },
  license: {
    // 批文列表
    listPage: params => axios.get('/uaa/license/listPage', { params: params }),
    // 批文详情
    detail: id => axios.get(`/uaa/license/getInfoById/${id}`)
  },
  // 价格行情 风控管理
  intelligence: {
    // 行情监控列表
    getPageRecord: params => axios.get('/uaa/intelligence/getPageRecord', { params: params }),
    listPage: params => axios.get('/uaa/intelligence/listCredit', { params: params }),
    // 新增价格信息
    marketMonitoringAdd: data => axios.post('/uaa/intelligence/add', data),
    // 修改价格信息
    marketMonitoringupdate: data => axios.put('/uaa/intelligence/update', data),
    // 查看商品价格更新记录
    listUpCreditById: id => axios.get('/uaa/intelligence/listUpCredit?id=' + id),
    // 获取货物的原产地列表
    getPlaceOriginList: id => axios.get(`/uaa/intelligence/getPlaceOriginList/${id}`)
  },
  // 河南项目 伙伴管理
  partnerManagement: {
    // 海外供应商 List列表页面
    overseasSuppliersList: params => axios.get('/uaa/enterprise/listPage', { params: params })
  },
  // 提单
  billofloading: {
    // 分页列表
    pageList: params => axios.get('/uaa/logistics/listPage', { params }),
    // 物流详情
    detail: id => axios.get(`/uaa/logistics/get/${id}`)
  },
  task: {
    companyAccess: {
      // 根据企业ID查询变更信息详情
      enterpriseModifyDetail: enterpriseId => axios.get(`/uaa/enterprise/modify/${enterpriseId}`),
      // 查询准入资料详情
      enterpriseMaterialDetail: admitId => axios.get(`/uaa/enterprise/admit/material/${admitId}`),
      // 查询尽调报告详情
      enterpriseInspectDetail: admitId => axios.get(`/uaa/enterprise/admit/inspect/${admitId}`),
      // 查询额度信息详情
      enterpriseQuotaDetail: admitId => axios.get(`/uaa/enterprise/admit/quota/${admitId}`),
      // 客户准入管理 额度信息Tab页 拉取详情
      quotaInformationDetail: id => axios.get(`/uaa/enterprise/admit/quota/adjustment/${id}`),
      // 企业信息管理-详情 仓储方审核
      currentInformationDetail: id => axios.get(`/uaa/enterprise/${id}`)
    },
    logistics: {
      // 物流转运详情
      detail: id => axios.get(`/uaa/logistics/transport/${id}`)
    },
    inWarehousing: {
      // 查询信息详情
      getDetail: id => axios.get(`/uaa/InWarehousing/${id}`),
      // 获取管理按订单信息
      getOrderNo: params => axios.get('/uaa/InWarehousing/getOrderNo', { params: params })
    },
    outWarehouse: {
      // 查询信息详情
      getDetail: stockOutId => axios.get(`/uaa/storage/pack/out/detail/${stockOutId}`),
      // 根据业务id查询证照材料
      licenceList: businessId => axios.get(`/uaa/licence/list/${businessId}`),
      // 根据申请付款UUID查询未提交除外付款结果
      getInfoByCollectionId: id => axios.get(`/uaa/collection/getInfoByCollectionId/${id}`)
    },
    fundManagement: {
      // 资金方管理 修改通过id拉取详情
      FunderDetails: id => axios.get('/uaa/enterprise/' + id),
      // 资金方管理 变更通过id拉取详情
      FunderChange: enterpriseId => axios.get('/uaa/enterprise/modify/' + enterpriseId),
      // 报关行管理  获取企业报价方案表
      EnterpriseQuotationScheme: (enterpriseId, scene) => axios.get(`/uaa/enterprise/scheme/list/${enterpriseId}/${scene}`)
    }
  },
  // 资金管理
  fundManagement: {
    // 授信管理
    credit: {
      // 列表
      listPage: params => axios.get('/uaa/capital/listCredit', { params: params }),
      // 详情
      getDetail: id => axios.get(`/uaa/capital/getInfoById/${id}`),
      // 用信台账
      listUseCredit: params => axios.get('/uaa/capital/listUseCredit', { params: params })
    },
    // 授信担保
    guarantee: {
      // 授信担保列表
      list: creditId => axios.get(`/uaa/guarantee/selCreditGuarantee/${creditId}`),
      // 详情
      getDetail: id => axios.get(`/uaa/guarantee/getInfoById/${id}`),
      // 根据用信id查询担保
      selCreditGuaranteeByUse: userCreditId => axios.get(`/uaa/guarantee/selCreditGuaranteeByUse/${userCreditId}`)
    },
    // 用信
    loan: {
      // 列表
      listPage: params => axios.get('/uaa/loan/listCredit', { params: params }),
      // 详情
      getDetail: id => axios.get(`/uaa/loan/getInfoById/${id}`)
    },
    // 还款
    repayment: {
      // 列表
      list: params => axios.get('/uaa/repayment/repaymentList', { params: params }),
      // 详情
      getDetail: id => axios.get(`/uaa/repayment/getInfoById/${id}`)
    },
    // 对外担保台账
    external: {
      // 列表
      listPage: params => axios.get('/uaa/external/listGrid', { params: params }),
      // 详情
      getDetail: id => axios.get(`/uaa/external/getInfoById/${id}`)
    }
  },
  // 仓储管理
  stock: {
    // 仓储列表
    listPage: params => axios.get('/uaa/stock/pack/listPage', { params: params }),
    // 仓储详情
    detail: id => axios.get(`/uaa/stock/pack/detail/${id}`)
  },
  payment: {
    // 全部付款信息列表
    listPaymentAll: params => axios.get('/uaa/payment/listPage', { params }),
    // 根据申请付款UUID查询未提交除外付款结果
    getInfoByPaymentId: id => axios.get(`/uaa/payment/getInfoByPaymentId/${id}`),
    myParment: params => axios.get('/uaa/collection/myParment', { params }),
    // 付款信息详情
    // 根据id 获取详情
    getInfoById: id => axios.get(`/uaa/payment/getInfoById/${id}`),
    // 支付要件数据检索（传订单ID 和 付款ID（新增传递空））
    selMode: (orderId, paymentId) => axios.get(`/uaa/payment/selMode/${orderId}/${paymentId}`)
  },
  collection: {
    // 全部收款信息列表
    listCollectionAll: params => axios.get('/uaa/collection/listPage', { params }),
    // 应收信息列表
    listCollectionToBePaid: params => axios.get('/uaa/collection/listCollectionToBePaid', { params }),
    // 实收信息列表
    listCollectionPaid: params => axios.get('/uaa/collection/listCollectionPaid', { params }),
    // 删除收款
    delCollection: id => axios.delete(`/uaa/collection/${id}`),
    // 推送资金方 - 不推送
    noPush: id => axios.get(`/uaa/collection/noPush/${id}`),
    // 推送
    push: collectionId => axios.get(`/uaa/collection/push/${collectionId}`),
    // 详情页下拉订单详情
    selOrder: () => axios.get('/uaa/collection/selOrder'),
    // 新增申请付款
    addCollection: data => axios.post('/uaa/collection/add', data),
    // 更新申请付款
    collectionUpdate: data => axios.put('/uaa/collection/update', data),
    // 根据id 获取详情
    getInfoById: id => axios.get(`/uaa/collection/getInfoById/${id}`),
    // 根据申请付款UUID查询未提交付款结果
    getInfoByCollectionIdOne: id => axios.get(`/uaa/collection/getInfoByCollectionIdOne/${id}`),
    // 根据申请付款UUID查询未提交除外付款结果
    getInfoByCollectionId: id => axios.get(`/uaa/collection/getInfoByCollectionId/${id}`),
    // 更新申请付款
    updatePayment: data => axios.put('/uaa/collection/update', data),
    // 付款完成 - 差额
    difference: collectionId => axios.get(`/uaa/collection/difference/${collectionId}`),
    // 付款完成
    addDifference: (collectionId, difference) => axios.put(`/uaa/collection/addDifference/${collectionId}/${difference}`),
    // 新增付款结果
    addResults: data => axios.post('/uaa/collection/addResults', data),
    // 更新付款结果
    updateResults: data => axios.put('/uaa/collection/updateResults', data),
    // pi分页列表
    piList: params => axios.get('/uaa/order/nt/listPiPage', { params }),
    // 货柜列表
    containerList: params => axios.get('/uaa/logistics/listPickUp', { params }),
    // 新增结算单
    addStatement: data => axios.post('/uaa/statement/addCurrency', data),
    // 更新结算单
    editStatement: data => axios.put('/uaa/statement/update', data),
    // 获取结算单详情
    statementDetail: params => axios.get('/uaa/statement/getInfoByBusinessid', { params })
  },
  warehouseManagement: {
    // 获取企业资格审查表-变更时scene传modify
    qualificationList: (enterpriseId, scene) => axios.get(`/uaa/enterprise/qualification/list/${enterpriseId}/${scene}`),
    // 报关行管理  获取企业报价方案表
    schemeList: (enterpriseId, scene) => axios.get(`/uaa/enterprise/scheme/list/${enterpriseId}/${scene}`)
  },
  // 仓储管理 按重量
  storageManagementByWeight: {
    // 入库计划管理
    inWarehouse: {
      // 分页列表
      listPage: params => axios.get('/uaa/storage/weight/in/listPage', { params }),
      // 新增入库计划
      add: data => axios.post('/uaa/storage/weight/in/add', data),
      // 修改入库计划
      update: data => axios.put('/uaa/storage/weight/in/update', data),
      // 提交入库计划
      commit: data => axios.put('/uaa/storage/weight/in/commit', data),
      // 获取详情
      detail: id => axios.get(`/uaa/storage/weight/in/detail/${id}`),
      // 删除
      del: id => axios.delete(`/uaa/storage/weight/in/delete/${id}`),
      // 获取订单列表
      orderList: () => axios.get('/uaa/storage/weight/in/orderList'),
      // 获取合同列表
      contractList: params => axios.get('/uaa/storage/weight/in/contractSealList', { params }),
      // 获取订单产品列表
      orderGoodsList: params => axios.get('/uaa/storage/weight/in/orderGoodsList', { params }),
      // 获取仓库详情
      getWarehouseDetail: warehouseId => axios.get(`/uaa/storage/warehouse/address/${warehouseId}`),
      // 获取订单详情
      getOrderDetail: params => axios.get('/uaa/order/getDetailByOrderId', { params }),
      // 企业列表
      companyList: orgType => axios.get(`/uaa/enterprise/admit/list/selector/${orgType}`),
      // 企业详情
      enterpriseDetail: id => axios.get(`/uaa/enterprise/get/${id}`),
      // 仓库管理员 已办
      doneSchedulePage: params => axios.get('/uaa/storage/weight/schedule/doneSchedulePage', { params }),
      // 仓库管理员待办
      schedulePage: params => axios.get('/uaa/storage/weight/schedule/schedulePage', { params }),
      // 交货记录
      deliveryRecordPage: params => axios.get('/uaa/storage/weight/in/deliveryRecordPage', { params }),
      // 申请付款
      applyPayment: data => axios.post('/uaa/storage/weight/payment/apply', data),
      // 入库记录
      inRecordPage: params => axios.get('/uaa/storage/weight/in/inRecordPage', { params })
    },
    // 入库单
    inList: {
      // 分页列表
      listPage: params => axios.get('/uaa/storage/weight/in/list/listPage', { params }),
      // 入库磅单列表
      getPoundByInListId: inListId => axios.get(`/uaa/storage/weight/in/list/getPoundByInListId/${inListId}`),
      // 新增入库单
      add: data => axios.post('/uaa/storage/weight/in/list/add_yd', data),
      // 删除入库磅单
      delPound: id => axios.delete(`/uaa/storage/weight/in/list/deletePound/${id}`),
      // 识别磅单信息
      parsePoundListByUrl: params => axios.get('/uaa/verification/parsePoundListByUrl', { params })
    },
    // 出库
    out: {
      // 库存查询
      stockedPage: params => axios.get('/uaa/storage/weight/payment/stockedPage', { params }),
      // 库存查询详情
      stockedDetail: params => axios.get('/uaa/storage/weight/payment/pickGoodsDetail', { params }),
      // 申请提货
      applyDelivery: data => axios.post('/uaa/storage/weight/out/pickGoodsApply', data),
      // 提货管理 分页列表
      deliveryListPage: params => axios.get('/uaa/storage/weight/out/pickGoodsListPage', { params }),
      // 提货/出库 详情
      outDetail: id => axios.get(`/uaa/storage/weight/out/pickGoodsDetail/${id}`),
      // 出库管理 分页列表
      outListPage: params => axios.get('/uaa/storage/weight/out/outWeightListPage', { params }),
      // 提交货转证明
      commitTransferProof: data => axios.put('/uaa/storage/weight/out/commitTransfer?outPlanId=' + data.outPlanId + '&transferWeight=' + data.transferWeight),
      // 出库记录
      outRecordPage: params => axios.get('/uaa/storage/weight/out/outRecordPage', { params })
    },
    // 出库单
    outList: {
      // 分页列表
      listPage: params => axios.get('/uaa/storage/weight/out/list/listPage', { params }),
      // 出库磅单列表
      getPoundByOutListId: outListId => axios.get(`/uaa/storage/weight/out/list/getPoundByOutListId/${outListId}`),
      // 新增出库单
      add: data => axios.post('/uaa/storage/weight/out/list/add_yd', data),
      // 删除出库磅单
      delPound: id => axios.delete(`/uaa/storage/weight/out/list/deletePound/${id}`)
    }
  }
}
export default api
