import axios from '../assets/js/http'
import MockAdapter from 'axios-mock-adapter'

import base from './data/base'

export default () => {
  const mockUrl = axios.defaults.baseURL + '/mock'
  if (process.env.VUE_APP_MOCK === 'force') {
    axios.defaults.baseURL = mockUrl
  }
  // eslint-disable-next-line no-unused-vars
  const baseUrl = axios.defaults.baseURL
  const mock = new MockAdapter(axios, { delayResponse: 200, onNoMatch: 'passthrough' })
  mock.onPost(mockUrl + '/b/login/login').reply(config => base.login(config))
  // 匹配非mock的请求
  mock.onAny().passThrough()
}
