import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import utils from '@/assets/js/utils'
import constants from '@/assets/js/constants'
import api from './assets/js/api'
import '@/assets/css/global.less'
import _ from 'lodash'
import NP from 'number-precision'
import mock from './mock/index'
import 'amfe-flexible/index.js'
import axios from 'axios'
import './assets/css/fontIcon.css'
if (['enable', 'force'].includes(process.env.VUE_APP_MOCK)) {
  mock()
}

Vue.use(Meta)

Vue.prototype.utils = utils
Vue.prototype.constants = constants
Vue.prototype.api = api
Vue.prototype.axios = axios
Vue.prototype._ = _
Vue.prototype.NP = NP
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
