
export default [
  {
    path: '/delivery/menu',
    name: 'deliveryMenu',
    meta: {
      requireAuth: true,
      title: '我要提货'
    },
    component: () => import('@/views/delivery/menu')
  },
  {
    path: '/delivery/apply',
    name: 'deliveryApply',
    meta: {
      requireAuth: true,
      title: '提货申请'
    },
    component: () => import('@/views/delivery/apply/list')
  },
  {
    path: '/delivery/apply/whole',
    name: 'deliveryApplyWhole',
    meta: {
      requireAuth: true,
      title: '申请整柜提货'
    },
    component: () => import('@/views/delivery/apply/whole')
  },
  {
    path: '/delivery/apply/open',
    name: 'deliveryApplyOpen',
    meta: {
      requireAuth: true,
      title: '申请拆柜提货'
    },
    component: () => import('@/views/delivery/apply/open')
  },
  {
    path: '/delivery/apply/openDetail',
    name: 'deliveryApplyOpenDetail',
    meta: {
      requireAuth: true,
      title: '申请拆柜提货'
    },
    component: () => import('@/views/delivery/apply/openDetail')
  },
  {
    path: '/delivery/apply/detail',
    name: 'deliveryApplyDetail',
    meta: {
      requireAuth: true,
      title: '库存详情'
    },
    component: () => import('@/views/delivery/apply/detail')
  },
  {
    path: '/delivery/apply/detailNext',
    name: 'deliveryApplyDetailNext',
    meta: {
      requireAuth: true,
      title: '库存详情'
    },
    component: () => import('@/views/delivery/apply/detailNext')
  },
  {
    path: '/delivery/record',
    name: 'deliveryRecord',
    meta: {
      requireAuth: true,
      title: '提货记录'
    },
    component: () => import('@/views/delivery/record/list')
  },
  {
    path: '/delivery/record/detail',
    name: 'deliveryRecordDetail',
    meta: {
      requireAuth: true,
      title: '提货记录详情'
    },
    component: () => import('@/views/delivery/record/detail')
  },
  // 收货单位 申请提货
  {
    path: '/storageManagement/applyDelivery',
    name: 'applyDelivery',
    meta: {
      requireAuth: true,
      title: '申请提货'
    },
    component: () => import('@/views/storageManagement/inWarehouse/applyDelivery')
  },
  // 收货单位 平台 库存详情
  {
    path: '/storageManagement/stockDetail',
    name: 'stockDetailByWeight',
    meta: {
      requireAuth: true,
      title: '库存详情'
    },
    component: () => import('@/views/storageManagement/stock/detail')
  },
  // 收货单位 提货详情
  {
    path: '/storageManagement/deliveryDetail',
    name: 'deliveryDetailByWeight',
    meta: {
      requireAuth: true,
      title: '提货详情'
    },
    component: () => import('@/views/storageManagement/delivery/detail')
  }
]
