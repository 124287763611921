
export default [
  {
    path: '/payment/list',
    name: 'paymentList',
    meta: {
      requireAuth: true,
      title: '我的付款'
    },
    component: () => import('@/views/payment/list')
  },
  {
    path: '/payment/detail',
    name: 'paymentDetail',
    meta: {
      requireAuth: true,
      title: '付款详情'
    },
    component: () => import('@/views/payment/detail')
  },
  {
    path: '/payment/management/list',
    name: 'paymentManagementList',
    meta: {
      requireAuth: true,
      title: '付款管理'
    },
    component: () => import('@/views/payment/management-list')
  },
  {
    path: '/payment/management/detail',
    name: 'paymentManagementDetail',
    meta: {
      requireAuth: true,
      title: '付款详情'
    },
    component: () => import('@/views/payment/management-detail')
  },
  {
    path: '/payment/management/do/detail',
    name: 'paymentManagementDoDetail',
    meta: {
      requireAuth: true,
      title: '付款详情'
    },
    component: () => import('@/views/payment/management-detail')
  },
  {
    path: '/payment/management/do/detail',
    name: 'paymentManagementDoDetail',
    meta: {
      requireAuth: true,
      title: '添加付款结果审核'
    },
    component: () => import('@/views/payment/do-detail')
  },
  {
    path: '/payment/collection/list',
    name: 'paymentCollectionList',
    meta: {
      requireAuth: true,
      title: '收款管理'
    },
    component: () => import('@/views/collection/list')
  },
  {
    path: '/payment/collection/detail',
    name: 'paymentCollectionDetail',
    meta: {
      requireAuth: true,
      title: '收款详情'
    },
    component: () => import('@/views/collection/detail')
  },
  {
    path: '/payment/collection/do/detail',
    name: 'paymentCollectionDoDetail',
    meta: {
      requireAuth: true,
      title: '添加收款结果审核'
    },
    component: () => import('@/views/collection/do-detail')
  },
  {
    path: '/payment/detail/appeal',
    name: 'paymentAppeal',
    meta: {
      requireAuth: true,
      title: '申述'
    },
    component: () => import('@/views/payment/appeal')
  }
]
