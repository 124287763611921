
export default [
  {
    path: '/signature/grant',
    name: 'signatureGrant',
    meta: {
      requireAuth: true,
      title: '管理员签章授权'
    },
    component: () => import('@/views/signature/grant')
  },
  {
    path: '/signature/agreement',
    name: 'signatureAgreement',
    meta: {
      requireAuth: true,
      title: '企业用户服务协议'
    },
    component: () => import('@/views/signature/agreement')
  },
  {
    path: '/signature/signSuccess',
    name: 'signatureSignSuccess',
    meta: {
      requireAuth: true,
      title: '通过'
    },
    component: () => import('@/views/signature/sign-success')
  },
  {
    path: '/signature/empowerSuccess',
    name: 'signatureEmpowerSuccess',
    meta: {
      requireAuth: true,
      title: '签章授权成功'
    },
    component: () => import('@/views/signature/empower-success')
  }
]
