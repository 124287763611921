
export default [
  {
    path: '/platform/service',
    name: 'platformService',
    meta: {
      requireAuth: true,
      title: '供应链服务'
    },
    component: () => import('@/views/platform-service')
  },
  {
    path: '/enterprise/service',
    name: 'enterpriseService',
    meta: {
      requireAuth: true,
      title: '供应链服务'
    },
    component: () => import('@/views/enterprise-service')
  },
  {
    path: '/service/menu',
    name: 'serviceMenu',
    meta: {
      requireAuth: true,
      title: '供应链服务'
    },
    component: () => import('@/views/serviceMenu')
  },
  {
    path: '/message',
    name: 'message',
    meta: {
      requireAuth: true,
      title: '消息'
    },
    component: () => import('@/views/message')
  },
  {
    path: '/message/detail',
    name: 'messageDetail',
    meta: {
      requireAuth: true,
      title: '消息详情'
    },
    component: () => import('@/views/message-detail')
  },
  {
    path: '/message/push',
    name: 'message',
    meta: {
      requireAuth: true,
      title: '消息推送'
    },
    component: () => import('@/views/message-push')
  },
  {
    path: '/file/detail',
    name: 'fileDetail',
    meta: {
      requireAuth: true,
      title: '附件详情'
    },
    component: () => import('@/views/attachment-detail')
  },
  {
    path: '/project/list',
    name: 'projectList',
    meta: {
      requireAuth: true,
      title: '项目管理'
    },
    component: () => import('@/views/project/list')
  },
  {
    path: '/project/detail',
    name: 'projectDetail',
    meta: {
      requireAuth: true,
      title: '项目详情'
    },
    component: () => import('@/views/project/detail')
  },
  {
    path: '/contract/list',
    name: 'contractList',
    meta: {
      requireAuth: true,
      title: '合同管理'
    },
    component: () => import('@/views/contract/list')
  },
  {
    path: '/contract/detail',
    name: 'contractDetail',
    meta: {
      requireAuth: true,
      title: '合同详情'
    },
    component: () => import('@/views/contract/detail')
  },
  {
    path: '/contract/audit',
    name: 'contractAudit',
    meta: {
      requireAuth: true,
      title: '合同归档审核'
    },
    component: () => import('@/views/contract/detail')
  },
  {
    path: '/order/list',
    name: 'orderList',
    meta: {
      requireAuth: true,
      title: '订单管理'
    },
    component: () => import('@/views/order/list')
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    meta: {
      requireAuth: true,
      title: '订单详情'
    },
    component: () => import('@/views/order/detail')
  },
  {
    path: '/license/list',
    name: 'licenseList',
    meta: {
      requireAuth: true,
      title: '批文管理'
    },
    component: () => import('@/views/license/list')
  },
  {
    path: '/license/detail',
    name: 'licenseDetail',
    meta: {
      requireAuth: true,
      title: '批文详情'
    },
    component: () => import('@/views/license/detail')
  },
  {
    path: '/intelligence/list',
    name: 'intelligenceList',
    meta: {
      requireAuth: true,
      title: '风控管理'
    },
    component: () => import('@/views/intelligence/list')
  },
  {
    path: '/intelligence/detail',
    name: 'intelligenceDetail',
    meta: {
      requireAuth: true,
      title: '价格更新详情'
    },
    component: () => import('@/views/intelligence/detail')
  },
  {
    path: '/order/myOrder',
    name: 'myOrderList',
    meta: {
      requireAuth: true,
      title: '我的订单'
    },
    component: () => import('@/views/order/myOrder')
  },
  {
    path: '/order/myOrderDetail',
    name: 'myOrderDetail',
    meta: {
      requireAuth: true,
      title: '订单详情'
    },
    component: () => import('@/views/order/myOrderDetail')
  },
  {
    path: '/order/apply',
    name: 'orderApply',
    meta: {
      requireAuth: true,
      title: '订单申请'
    },
    component: () => import('@/views/order/apply')
  },
  {
    path: '/order/edit',
    name: 'orderEdit',
    meta: {
      requireAuth: true,
      title: '订单修改'
    },
    component: () => import('@/views/order/apply')
  },
  {
    path: '/contract/myContract',
    name: 'myContractList',
    meta: {
      requireAuth: true,
      title: '我的合同'
    },
    component: () => import('@/views/contract/myContract')
  },
  {
    path: '/contract/myContractDetail',
    name: 'myContractDetail',
    meta: {
      requireAuth: true,
      title: '合同详情'
    },
    component: () => import('@/views/contract/myContractDetail')
  },
  {
    path: '/fund/menu',
    name: 'fundMenu',
    meta: {
      requireAuth: true,
      title: '资金管理'
    },
    component: () => import('@/views/fund/menu')
  },
  {
    path: '/external/list',
    name: 'externalList',
    meta: {
      requireAuth: true,
      title: '对外担保台账'
    },
    component: () => import('@/views/fund/external/list')
  },
  {
    path: '/external/detail',
    name: 'externalDetail',
    meta: {
      requireAuth: true,
      title: '对外担保台账详情'
    },
    component: () => import('@/views/fund/external/detail')
  },
  {
    path: '/useCredit/list',
    name: 'useCreditList',
    meta: {
      requireAuth: true,
      title: '用信台账'
    },
    component: () => import('@/views/fund/useCredit/list')
  },
  {
    path: '/useCredit/detail',
    name: 'useCreditDetail',
    meta: {
      requireAuth: true,
      title: '用信台账详情'
    },
    component: () => import('@/views/fund/useCredit/detail')
  },
  {
    path: '/credit/list',
    name: 'creditList',
    meta: {
      requireAuth: true,
      title: '授信管理'
    },
    component: () => import('@/views/fund/credit/list')
  },
  {
    path: '/credit/detail',
    name: 'creditDetail',
    meta: {
      requireAuth: true,
      title: '授信详情'
    },
    component: () => import('@/views/fund/credit/detail')
  },
  {
    path: '/storage/list',
    name: 'storageList',
    meta: {
      requireAuth: true,
      title: '仓储管理'
    },
    component: () => import('@/views/storage/list')
  },
  {
    path: '/storage/detail',
    name: 'storageDetail',
    meta: {
      requireAuth: true,
      title: '仓储详情'
    },
    component: () => import('@/views/storage/detail')
  },
  {
    path: '/storage/inventoryDetail',
    name: 'inventoryDetail',
    meta: {
      requireAuth: true,
      title: '库存详情'
    },
    component: () => import('@/views/storage/inventoryDetail')
  }
]
