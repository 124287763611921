
export default [
  {
    path: '/personal/password',
    name: 'personalPassword',
    meta: {
      requireAuth: true,
      title: '初次修改密码'
    },
    component: () => import('@/views/personal/password')
  },
  {
    path: '/personal/information',
    name: 'personalInformation',
    meta: {
      requireAuth: true,
      title: '基本信息填报'
    },
    component: () => import('@/views/personal/information')
  },
  {
    path: '/personal/information/company',
    name: 'personalInformationCompany',
    meta: {
      requireAuth: true,
      title: '企业认证'
    },
    component: () => import('@/views/personal/company')
  },
  {
    path: '/personal/information/administrators',
    name: 'personalInformationAdministrators',
    meta: {
      requireAuth: true,
      title: '管理员认证'
    },
    component: () => import('@/views/personal/administrators')
  },
  {
    path: '/personal/information/attestationSuccess',
    name: 'personalInformationAttestationSuccess',
    meta: {
      requireAuth: true,
      title: '身份认证通过'
    },
    component: () => import('@/views/personal/attestation-success')
  }
]
