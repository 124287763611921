
export default [
  {
    path: '/billofloading/list',
    name: 'personalList',
    meta: {
      requireAuth: true,
      title: '我的提单'
    },
    component: () => import('@/views/billofloading/list')
  },
  {
    path: '/billofloading/detail',
    name: 'personalDetail',
    meta: {
      requireAuth: true,
      title: '提单详情'
    },
    component: () => import('@/views/billofloading/detail')
  },
  {
    path: '/invoice/list',
    name: 'invoiceList',
    meta: {
      requireAuth: true,
      title: '我的发票'
    },
    component: () => import('@/views/invoice/list')
  },
  {
    path: '/invoice/detail',
    name: 'invoiceDetail',
    meta: {
      requireAuth: true,
      title: '发票详情'
    },
    component: () => import('@/views/invoice/detail')
  },
  {
    path: '/invoice/invoiceList',
    name: 'invoiceinvoiceList',
    meta: {
      requireAuth: true,
      title: '我的发票'
    },
    component: () => import('@/views/invoice/invoiceList')
  },
  {
    path: '/invoice/platformList',
    name: 'invoiceplatformList',
    meta: {
      requireAuth: true,
      title: '我的发票'
    },
    component: () => import('@/views/invoice/platform-list')
  },
  {
    path: '/invoice/add',
    name: 'invoiceAdd',
    meta: {
      requireAuth: true,
      title: '新增发票'
    },
    component: () => import('@/views/invoice/add')
  },
  {
    path: '/invoice/platform/edit',
    name: 'invoicePlatformEdit',
    meta: {
      requireAuth: true,
      title: '新增发票'
    },
    component: () => import('@/views/invoice/platform-edit')
  }
]
