/**
 * base Created by LiuSiyao on 2020/8/4.
 */
import BaseData from '@/mock/template/base-data'

export default {
  login: () => {
    const data = new BaseData({
      token: 'mock-token'
    }, '登录成功')
    return [200, data.mock]
  },
  logout: () => {
    const data = new BaseData({}, '退出成功')
    return [200, data.mock]
  },
  saveSuccess: () => {
    const data = new BaseData({}, '保存成功')
    return [200, data.mock]
  },
  submitSuccess: () => {
    const data = new BaseData({}, '提交成功')
    return [200, data.mock]
  },
  submitBack: () => {
    const data = new BaseData({}, '退回成功')
    return [200, data.mock]
  },
  delSuccess: () => {
    const data = new BaseData({}, '删除成功')
    return [200, data.mock]
  },
  operateSuccess: () => {
    const data = new BaseData({}, '操作成功')
    return [200, data.mock]
  }
}
