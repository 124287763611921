const constants = {
  systemType: {
    gzbSystem: 'gzb-system',
    nongtouSystem: 'nongtou-system'
  },
  // 移动端 供应链服务菜单 父菜单id
  serviceMenu: {
    platFormParentId: 'c211ed0aab2d4dac8163132637311db0', // 平台用户
    enterpriseParentId: 'b56d7a63d62249df8dcaa926ae5099b8' // 企业用户
  },
  examineTitle: '版权所有     河南联创融久供应链管理有限公司',
  inputAlign: 'right',
  // 字数校验提示
  rulesExclude50: /^[\S\n\s]{0,50}$/,
  rulesToolkit50: '超过最大字数限制，最多输入50字',
  // 字数校验提示
  rulesExclude20: /^[\S\n\s]{0,20}$/,
  rulesToolkit20: '超过最大字数限制，最多输入20字',
  // 纯数字
  rulesNumber: /^([0-9]*$)/,
  rulesToolkitsNumber: '请输入数字',
  // 手机号
  rulesExcludePhone: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
  rulesToolkitPhone: '请输入正确的手机号',
  // 20位数字
  rules20: /^\d{1,20}$/,
  rulesToolkitsNumber20: '请输入20位以内数字',
  // 4位整数
  rulesInteger4: /^\d{4}$/,
  rulesToolkitInteger4: '请输入4位数字',
  // 邮箱
  email: /^([a-zA-Z0-9_-]{1,16})@([a-zA-Z0-9]{1,9})(\.[a-zA-Z0-9]{1,9}){0,3}(\.(?:com|net|org|edu|gov|mil|cn|us)){1,4}$/,
  emailToolkit: '请输入正确的邮箱',
  // 不需要token 身份校验的接口
  urlNoToken: ['/uaa/file/upload'],
  // 证件类型
  typeCertificateType: [
    { label: '居民身份证', value: '0' }
  ],
  nongtoutypeCertificateType: [
    { label: '居民身份证', value: '1' }
  ],
  authStatus: [
    { label: '未完成', value: 0 },
    { label: '已完成', value: 1 }
  ],
  companyType: [
    { label: '平台管理员', value: 0 },
    { label: '平台普通用户', value: 1 },
    { label: '资金方普通用户', value: 2 },
    { label: '企业客户', value: 3 },
    { label: '企业管理员', value: 4 },
    { label: '个人用户', value: 5 },
    { label: '超级管理员', value: 6 },
    { label: '仓库管理员', value: 7 },
    { label: '贸易执行企业管理员', value: 8 },
    { label: '贸易执行企业普通用户', value: 9 },
    { label: '资金方管理员', value: 10 }
  ],
  // 发票种类
  invoiceType: [
    { value: '0', label: '进项发票' },
    { value: '1', label: '销项发票' }
  ],
  invoiceSendType: [
    { value: 1, label: '海外供应商' },
    { value: 2, label: '企业客户' },
    { value: 3, label: '资金方' },
    { value: 4, label: '仓储方' },
    { value: 5, label: '报关行' },
    { value: 6, label: '其他' }
  ],
  // 发票的类型
  invoiceTypes: [
    { value: '0', label: '运费发票' },
    { value: '1', label: '货物发票' },
    { value: '2', label: '服务费发票' },
    { value: '3', label: '运杂费发票' },
    { value: '4', label: '其他' }
  ],
  ExpenseType: [
    { value: '0', label: '货物费发票' },
    { value: '1', label: '运输费发票' },
    { value: '2', label: '报关费发票' },
    { value: '3', label: '服务费发票' },
    { value: '4', label: '冷藏类发票' },
    { value: '5', label: '改港费发票' },
    { value: '6', label: '保险费发票' },
    { value: '7', label: '其他费发票' }
  ],
  // 产品类型
  productType: [
    { label: '收储宝', value: 0 },
    { label: '在途宝', value: 1 },
    { label: '应收宝', value: 2 },
    { label: '代采宝', value: 3 }
  ],
  ProjectPlatformServiceType: [
    { label: '固定费用', value: 0 },
    { label: '阶梯固定费用', value: 1 },
    { label: '平台代理', value: 2 },
    { label: '第三方代理', value: 3 }
  ],
  // 签署方性质
  natureSignatory: [
    { value: 0, label: '平台方', isWarehouseManager: true },
    { value: 1, label: '企业客户' },
    { value: 2, label: '贸易执行企业', isWarehouseManager: true },
    { value: 3, label: '资金方', isWarehouseManager: true },
    { value: 4, label: '仓储方' },
    { value: 5, label: '海外供应商' },
    { value: 6, label: '报关厅' }
  ],
  contractStyle: [
    { value: 0, label: '订单类-销售合同' },
    { value: 1, label: '订单类-采购合同' },
    { value: 2, label: '订单类-保管合同' },
    { value: 3, label: '其他类-仓储合同' }
  ],
  contractType: [
    { value: 0, label: '未提交' },
    { value: 1, label: '审核中' },
    { value: 2, label: '审核退回' },
    { value: 3, label: '审核通过' },
    { value: 4, label: '审核拒绝' }
  ],
  contractStatus: [
    { value: 0, label: '未提交' },
    { value: 1, label: '审核中' },
    { value: 2, label: '审核拒绝' },
    { value: 3, label: '待归档' },
    { value: 4, label: '归档审核中' },
    { value: 5, label: '已归档' }
  ],
  // 订单状态
  orderStatus: [
    { value: 0, label: '未提交' },
    { value: 1, label: '平台审核中' },
    { value: 2, label: '平台审核拒绝' },
    { value: 3, label: '待资金方分配' },
    { value: 4, label: '待资金方审核' },
    { value: 5, label: '资金方审核中' },
    { value: 6, label: '资金方审核拒绝' },
    { value: 7, label: '待执行' },
    { value: 8, label: '订单执行中' },
    { value: 9, label: '订单完成' }
  ],
  companyName: [
    { value: '0', label: '农投发展' },
    { value: '1', label: '联创融久' },
    { value: '2', label: '农投保理' }
  ],
  deliveryStatus: [
    { label: '待审核', value: 0 },
    { label: '审核中', value: 1 },
    { label: '上级退回', value: 2 },
    { label: '审核拒绝', value: 3 },
    { label: '出库中', value: 4 },
    { label: '已出库', value: 5 }
  ],
  outType: [
    { label: '整柜提货', value: 1 },
    { label: '拆柜提货', value: 2 }
  ],
  outMethod: [
    { label: '按件提货', value: 1 },
    { label: '按重量提货', value: 2 }
  ],
  // 文件材料状态
  categoryStatus: [
    { label: '无误', value: 0 },
    { label: '有误', value: 1 }
  ],
  paymentTypeList: [
    { value: 0, label: '未提交' },
    { value: 1, label: '平台审核中' },
    { value: 2, label: '上级退回' },
    { value: 3, label: '平台审核拒绝' },
    { value: 4, label: '待推送' },
    { value: 5, label: '推送审核中' },
    { value: 6, label: '推送审核拒绝' },
    { value: 7, label: '待付款' },
    { value: 8, label: '添加付款结果' },
    { value: 9, label: '已完成' }
  ],
  // 报关准备状态
  resultStatus: [
    { value: 0, label: '未提交' },
    { value: 1, label: '审核中' },
    { value: 2, label: '上级退回' },
    { value: 3, label: '审核通过' }
  ],
  dctTpNm: [
    { value: '0', label: '冻品_销售通用结算单' },
    { value: '1', label: '冻品_提货结算单' }
  ],
  creditSubject: [
    { value: '0', label: '农投发展' },
    { value: '1', label: '联创融久' },
    { value: '2', label: '农投保理' }
  ],
  creditTerm: [
    { value: 0, label: '6个月' },
    { value: 1, label: '12个月' },
    { value: 2, label: '两年' },
    { value: 3, label: '三年' }
  ],
  // 付款条款
  PaymentProvision: [
    { value: '1', label: 'FOB' },
    { value: '2', label: 'CIF' },
    { value: '3', label: 'CFR' },
    { value: '4', label: 'FCA' },
    { value: '5', label: 'CIP' },
    { value: '6', label: 'CPT' }
  ],
  // 担保方式
  guaranteeMethod: [
    { value: 0, label: '抵押担保' },
    { value: 1, label: '质押担保' },
    { value: 2, label: '保证担保' },
    { value: 3, label: '其它担保' }
  ],
  Distinguish: [
    { value: 1, label: '销项发票' },
    { value: 2, label: '进项发票' }
  ],
  InType: [
    { value: 1, label: '整柜入库' },
    { value: 2, label: '拆柜入库' }
  ],
  LogisticsMode: [
    { value: 0, label: '海运' },
    { value: 1, label: '汽海联运' }
  ],
  businessSnType: [
    { value: '1', label: '身份证' }
  ]
}
export default constants
