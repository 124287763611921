import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import personal from './personal'
import signature from './signature'
import delivery from './delivery'
import billofloading from './billofloading.js'
import business from './business'
import task from '@/router/task'
import payment from '@/router/payment'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index'),
    meta: {
      title: '首页',
      requireAuth: true
    }
  },
  {
    path: '/index',
    name: 'authorization',
    component: () => import('../views/index'),
    meta: {
      title: '授权跳转',
      requireAuth: true
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/personal'),
    meta: {
      title: '我的',
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),
    meta: {
      title: '登录',
      requireAuth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register'),
    meta: {
      title: '注册',
      requireAuth: false
    }
  },
  ...personal,
  ...signature,
  ...delivery,
  ...billofloading,
  ...signature,
  ...business,
  ...task,
  ...payment
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  store.commit('updateEmpInfo')
  store.commit('updateEmployeeInfo')
  if (to.meta.requireAuth) {
    if (store.state.authInfo.token) {
      next()
    } else {
      if (to.query.code) {
        window.location.href = `/login?code=${to.query.code}`
      } else {
        window.location.href = '/login'
      }
    }
  } else {
    next()
  }
})
export default router
