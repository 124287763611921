import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authInfo: {
      token: '',
      refreshToken: '',
      tokenType: ''
    },
    employeeInfo: {},
    // 菜单
    sideMenu: [],
    // 选中菜单
    activeMenu: '',
    // 菜单栏伸缩状态
    spread: '',
    // 功能列表
    featureList: []
  },
  mutations: {
    // 更新token信息
    updateEmpInfo (state) {
      state.authInfo.token = localStorage.getItem('token') || ''
      state.authInfo.refreshToken = localStorage.getItem('refreshToken') || ''
      state.authInfo.tokenType = localStorage.getItem('tokenType') || ''
    },
    // 更新用户信息
    updateEmployeeInfo (state) {
      const employeeInfo = window.localStorage.getItem('employeeInfo')
      state.employeeInfo = JSON.parse(employeeInfo) || {}
    },
    // 移除用户信息
    removeEmpInfo (state) {
      state.authInfo.token = ''
      state.authInfo.refreshToken = ''
      state.authInfo.tokenType = ''
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('tokenType')
    },
    // 移除失效token
    removeToken (state) {
      state.authInfo.token = ''
      localStorage.removeItem('token')
    },
    // 更新菜单
    updateSideMenu (state, payload) {
      state.sideMenu = payload
    },
    // 更新功能列表
    updateFeatureList (state, payload) {
      state.featureList = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
