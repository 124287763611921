<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  metaInfo () {
    return {
      title: this.$route.meta.title,
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - ${process.env.VUE_APP_PROJECT_NAME}` : process.env.VUE_APP_PROJECT_NAME
      }
    }
  },
  components: {
  },
  data () {
    return {
    }
  },
  created () {
    if (this.$store.state.authInfo.token) this.utils.getDict()
  }
}
</script>

<style>
  .van-tabs__line {
    background-color: #1989fa !important;
  }
</style>
