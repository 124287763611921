import commonAxios from 'axios'
import { Toast } from 'vant'
import store from '../../store/index'
import _ from 'lodash'
import api from './api'
// axios 配置
commonAxios.defaults.timeout = process.env.VUE_APP_REQUEST_TIMEOUT * 1000
commonAxios.defaults.baseURL = process.env.VUE_APP_REQUEST_BASE_URL
if (localStorage.getItem('token')) {
  commonAxios.defaults.headers = {
    Authorization: `${localStorage.getItem('tokenType')} ${localStorage.getItem('token')}`
  }
}

// http request 拦截器
commonAxios.interceptors.request.use(function (config) {
  if (store.state.authInfo.token || localStorage.getItem('token')) {
    const tokenType = store.state.authInfo.tokenType || localStorage.getItem('tokenType')
    const token = store.state.authInfo.token || localStorage.getItem('token')
    config.headers = {
      Authorization: `${tokenType} ${token}`
    }
  } else {
    delete config.headers.Authorization
  }
  if (!config.params) {
    config.params = {}
  }
  config.params._t = Date.parse(new Date()) / 1000
  return config
}, function (err) {
  return Promise.reject(err)
})
const fail = (error) => {
  Toast.fail(_.get(error, 'data.message') || _.get(error, 'response.data.message') || '未登录，请重新登录')
  store.commit('removeEmpInfo')
  window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
  return Promise.reject(error)
}
// http response 拦截器
commonAxios.interceptors.response.use(function (response) {
  if (response.status === 200 && response.data.success === false) {
    Toast.fail(response.data.message || '系统异常，请稍后重试')
    return Promise.reject(response)
  } else {
    return response
  }
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401: // 未登录
        if (error.config.customErr && error.config.customErr.code === 401) {
          return Promise.reject(error)
        }
        // 用更新token重新登录
        if (localStorage.getItem('refreshToken')) {
          store.commit('removeToken')
          return api.base.goLogin({
            client_id: 'c1',
            client_secret: 'secret',
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('refreshToken')
          }).then(function (result) {
            localStorage.setItem('token', result.data.data.access_token)
            localStorage.setItem('refreshToken', result.data.data.refresh_token.value)
            localStorage.setItem('tokenType', _.upperFirst(result.data.data.token_type))
            store.commit('updateEmpInfo')
            error.config.headers.Authorization = `${store.state.authInfo.tokenType} ${store.state.authInfo.token}`
            return commonAxios(error.config)
          }).catch(function (error) {
            return fail(error)
          })
        } else {
          return fail(error)
        }
      case 409: // 操作冲突
        if (error.config.customErr && error.config.customErr.code === 409) {
          return Promise.reject(error)
        } else {
          Toast.fail(error.response.data.message)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        break
      default:
        if (!(error.config.customErr && error.config.customErr.code === 'default')) {
          if (error.config.responseType === 'blob') {
            const reader = new FileReader()
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              Toast.fail(data.message)
            }
            reader.readAsText(error.response.data)
          } else {
            Toast.fail(error.response.data.message || '系统异常，请稍后重试')
          }
        }
        return Promise.reject(error)
    }
  } else {
    error.response = {}
    if (!(error.config.customErr && error.config.customErr.code === 'default')) {
      Toast.fail('系统异常，请稍后重试')
    }
    return Promise.reject(error.response.data)
  }
})
export default commonAxios
