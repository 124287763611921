export default [
  {
    path: '/task/company-access',
    name: 'taskCompanyAccess',
    meta: {
      requireAuth: true,
      title: '企业准入审批'
    },
    component: () => import('@/views/task/company-access')
  },
  {
    path: '/task/logistics-transport',
    name: 'taskLogisticsTransport',
    meta: {
      requireAuth: true,
      title: '转运管理审批'
    },
    component: () => import('@/views/task/logistics-transport')
  },
  {
    path: '/task/quota-change',
    name: 'taskQuotaChange',
    meta: {
      requireAuth: true,
      title: '额度调整审批'
    },
    component: () => import('@/views/task/quota-change')
  },
  {
    path: '/task/warehouse-audit',
    name: 'warehouseAudit',
    meta: {
      requireAuth: true,
      title: '仓储方审批'
    },
    component: () => import('@/views/task/warehouse-audit')
  },
  {
    path: '/task/warehouse-audit-change',
    name: 'warehouseAuditChange',
    meta: {
      requireAuth: true,
      title: '变革仓储方审批'
    },
    component: () => import('@/views/task/warehouse-audit-change')
  },
  {
    path: '/task/receipt-plan-approval',
    name: 'receiptPlanApproval',
    meta: {
      requireAuth: true,
      title: '入库计划'
    },
    component: () => import('@/views/task/receipt-plan-approval')
  },
  {
    path: '/task/receipt-plan-approval-detail',
    name: 'receiptPlanApprovalDetail',
    meta: {
      requireAuth: true,
      title: '入库计划详情'
    },
    component: () => import('@/views/task/receipt-plan-approval-detail')
  },
  {
    path: '/task/release-audit',
    name: 'releaseAudit',
    meta: {
      requireAuth: true,
      title: '放货审核'
    },
    component: () => import('@/views/task/release-audit')
  },
  {
    path: '/task/release-audit-detail',
    name: 'releaseAuditDetail',
    meta: {
      requireAuth: true,
      title: '放货审核详情'
    },
    component: () => import('@/views/task/release-audit-detail')
  },
  {
    path: '/task/customs-broker-approval',
    name: 'customsBrokerApproval',
    meta: {
      requireAuth: true,
      title: '报关行审核'
    },
    component: () => import('@/views/task/customs-broker-approval')
  },
  {
    path: '/task/customs-broker-approval-change',
    name: 'customsBrokerApprovalChange',
    meta: {
      requireAuth: true,
      title: '变更报关行审核'
    },
    component: () => import('@/views/task/customs-broker-approval-change')
  }
]
