/**
 * baseData Created by LiuSiyao on 2019/10/21.
 */
import moment from 'moment'
import Mock from 'mockjs'

class BaseData {
  constructor (data = {}, message = '请求成功', status = true) {
    this.template = {
      success: status,
      timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      data: data,
      message: message
    }
  }

  get mock () {
    return Mock.mock(this.template)
  }
}
export default BaseData
